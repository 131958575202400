export default defineNuxtRouteMiddleware((to, from) => {
  //keepalive缓存判断 系统BUG 临时解决方法 不完美 任意一次将to/from.meta.keepalive=false之后 全部变false
  console.log('useAliveList().value', useAliveList().value,to)
  if (useAliveList().value.includes(to.name)) {
    to.meta.keepalive = true
  } else {
    useAliveList('add', to.name?.toString()) //添加到缓存列表
    if (to.matched.length > 0 && to.matched[0].meta.keepalive) {
      to.meta.keepalive = false
    } else {
      to.meta.keepalive = true
    }
  }
  if (to.matched.length > 0) to.matched[0].meta.keepalive = true
})
