const useKeepAlive = () =>
  useState('keepAlive', () => {
    if (process.server) {
      return ['!']
    } else {
      return ['!']
    }
  })
export const useAliveList = (type: string = '', key: string = '') => {
  const aliveMax: number = 20 // 定义最大缓存页数量
  const list: any = useKeepAlive() // 获取现有keepalive列表
  if (type === 'add' && key) {
    if (list.value.includes(key)) {
      list.value = list.value.filter((i: keyof string) => i !== key); list.value.unshift(key)
    } else {
      list.value.unshift(key)
      if (list.value.length > aliveMax) { list.value.length = aliveMax }
    }
  } else if (type === 'del' && key) {
    list.value = list.value.filter((i: keyof string) => i !== key)
  } else if (type === 'non') {
    list.value = ['!']
  } else if (!type && !key) {
    return list
  }
}
